html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: auto;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  /* max-width: 500px; */
  margin: 0 auto;
  padding: 0;
}

* {
  box-sizing: border-box;
}

#root {
  min-height: 100vh;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::placeholder {
  color: #d4d6d9;
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-width: 1100px) {
  body {
    background: url("./assets/character/book1.png") no-repeat fixed,
      url("./assets/character/book2.png") no-repeat fixed;
    background-position: left bottom, right bottom;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
  color: #000;
}
